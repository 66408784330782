import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link onClick={event =>  window.location.href='https://jaseunda.me/about'}>Contact</Link>
        </li>
        <li>
          <Link onClick={event =>  window.location.href='https://jaseunda.me'}>About me</Link>
        </li>
        <li>
          <Link onClick={event =>  window.location.href='https://discord.gg/F5vdx8VC9p'}>FAQ's</Link>
        </li>
        <li>
          <Link onClick={event =>  window.location.href='https://discord.gg/ECsR7FYjnM'}>Support</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;